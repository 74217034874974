/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from 'components/Button'
import { Modal, ModalNew } from 'components/Modal'
import { Title, TitleH1 } from 'components/TitleComponent'
import { AvailableFiltersList } from 'components/filters/AvailableFiltersList'
import { EmailInput } from 'components/form/EmailInput'
import { Form, IChildrenProps } from 'components/form/Form'
import { MessageInput } from 'components/form/MessageInput'
import { PhoneInput } from 'components/form/PhoneInput'
import { graphql } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import { useFormHandler } from 'hooks/useFormHandler'
import { LocalizedLink } from 'i18n/LocalizedLink'
import { Layout } from 'layout/Layout'
import Previous from 'assets/icons/left.svg'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors } from 'styles/colors'
import { displayWidth } from 'styles/width'
import { sendEvent } from 'tracking'
import { imagesDataProp } from 'types/interfaces'
import { getImageByImageName } from 'utils/getImageByImageName'
import { Input } from 'components/form/Input'

const ImgClickWrapper = styled.div`
    width: 99%;
    height: auto;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    @media (min-width: ${displayWidth.tablet}) {
        width: 49%;
    }
`

const InputBlock = styled.div`
    display: flex;
    flex-direction: column;
    @media (min-width: ${displayWidth.desktop}) {
        width: 100%;
    }
`

const Image = styled(Img)<{ fluid: FluidObject }>`
    width: 100%;
    height: auto;
    color: transparent;
`

const ImageB = styled(Img)<{ fluid: FluidObject }>`
    width: 100%;
    height: 100%;
`

const Flex = styled.div`
    gap: 12px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
    @media (min-width: ${displayWidth.tablet}) {
        gap: 2%;
    }
`
const FlexLabel = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    @media (min-width: ${displayWidth.tablet}) {
        flex-direction: row;
        gap: 10px;
    }
`

const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 16px;
    @media (min-width: ${displayWidth.tablet}) {
        padding: 30px 10%;
    }
`
const TitleS = styled(Title)`
    text-align: center;
    margin: 10px 0;
    font-size: 22px;
    @media (min-width: ${displayWidth.tablet}) {
        margin: 20px auto;
        font-size: 30px;
    }
`
const TitleH1S = styled(TitleH1)`
    text-align: center;
    margin: 22px 0px;
    font-size: 24px;
    @media (min-width: ${displayWidth.tablet}) {
        margin: 20px auto;
        font-size: 36px;
    }
`
const LocalizedLinkS = styled(LocalizedLink)`
    text-align: center;
`

const ButtonStyled = styled(Button)`
    margin: 10px 0;
    width: 100%;
    z-index: 3;
    padding: 10px 30px;
    background-color: ${colors.darkText};
    color: ${colors.white};
    border-width: 4px;
    :hover {
        background-color: #000;
        color: #fff;
    }
    @media (min-width: ${displayWidth.tablet}) {
        width: auto;
        margin: 50px auto;
    }
`
const TitleStyled = styled(Title)`
    margin: 50px 0;
    @media (orientation: landscape) {
        margin: 16px 0;
    }
`

const InputHidden = styled(Input)`
    display: none;
`

const BackButton = styled(Button)`
    display: flex;
    position: absolute;
    top: 6px;
    left: 16px;
    width: 110px;
    height: 26px;
    background-color: ${colors.white};
    color: ${colors.darkText};
    :hover {
        background-color: lightgray;
    }
    @media (min-width: ${displayWidth.tablet}) {
        top: 16px;
    }
`

const ArrowPrevious = styled(Previous)`
    width: 22px;
    height: 22px;
`
export interface ProjectImages {
    relativeDirectory: string
    childImageSharp: {
        fluid: FluidObject
        parent: { name: string }
        id: string
    }
}

const HouseLayout = ({
    data,
}: {
    data: {
        allHousesYaml: {
            edges: {
                node: any
            }[]
        }
        allImageSharp: imagesDataProp
    }
}) => {
    const { i18n, t } = useTranslation()
    const houseData = data.allHousesYaml.edges[0]
    const { description, alt, title, h1 } = houseData.node[i18n.language]
    const [isModalOpen, setModalIsOpen] = useState(false)
    const [initialSlideIndex, setInitialSlideIndex] = useState(0)
    const [isOrderModalOpen, setOrderModalIsOpen] = useState(false)
    const [isAskModalOpen, setAskModalIsOpen] = useState(false)

    // TODO: move to Form
    const {
        handleSubmitStatus,
        handleFormSendStart,
        formSendStatus,
        setFormSendStatus,
    } = useFormHandler()

    return (
        <Layout>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
            </Helmet>
            <Wrapper>
                {typeof history !== 'undefined' &&
                history.state?.prevPath === 'houses' ? (
                    <BackButton onClick={() => history.back()}>
                        <ArrowPrevious />
                        {t('back')}
                    </BackButton>
                ) : (
                    <LocalizedLink to="houses">
                        <BackButton>
                            <ArrowPrevious />
                            {t('back')}
                        </BackButton>
                    </LocalizedLink>
                )}
                <TitleH1S>{h1}</TitleH1S>
                {alt.map((_: string, i: number) => {
                    if (i % 2 === 0) {
                        return (
                            <React.Fragment key={i}>
                                <TitleS>
                                    {t(
                                        `houses.floor${Math.round((i + 1) / 2)}`
                                    )}
                                </TitleS>

                                <Flex>
                                    <ImgClickWrapper
                                        onClick={() => {
                                            setModalIsOpen(true)
                                            setInitialSlideIndex(i)
                                        }}
                                    >
                                        <Image
                                            fluid={
                                                getImageByImageName(
                                                    data.allImageSharp,
                                                    houseData.node.previewImage[
                                                        i
                                                    ]
                                                ).fluid
                                            }
                                            loading="eager"
                                            alt={alt[i]}
                                            title={alt[i]}
                                        />
                                        <span>{t('houses.furniture')}</span>
                                    </ImgClickWrapper>
                                    <ImgClickWrapper
                                        onClick={() => {
                                            setModalIsOpen(true)
                                            setInitialSlideIndex(i + 1)
                                        }}
                                    >
                                        <Image
                                            fluid={
                                                getImageByImageName(
                                                    data.allImageSharp,
                                                    houseData.node.previewImage[
                                                        i + 1
                                                    ]
                                                ).fluid
                                            }
                                            loading="eager"
                                            alt={alt[i + 1]}
                                            title={alt[i + 1]}
                                        />
                                        <span>{t('houses.light')}</span>
                                    </ImgClickWrapper>
                                </Flex>
                            </React.Fragment>
                        )
                    } else {
                        return null
                    }
                })}
            </Wrapper>

            <Wrapper>
                <AvailableFiltersList filters={houseData.node.filters} all />
                <FlexLabel>
                    <LocalizedLinkS
                        to={'exampleArchitect'}
                        onClick={() => {
                            sendEvent('Click', {
                                eventCategory: 'ShowExampleOfProject',
                                placement: 'Our Services',
                                target: 'exampleArchitect',
                            })
                        }}
                    >
                        <ButtonStyled
                            aria-label={(t('goTo'), t('comercialForm.example'))}
                        >
                            {t('houses.show_example')}
                        </ButtonStyled>
                    </LocalizedLinkS>

                    <ButtonStyled onClick={() => setOrderModalIsOpen(true)}>
                        {t('houses.order')}
                    </ButtonStyled>

                    <ButtonStyled onClick={() => setAskModalIsOpen(true)}>
                        {t('houses.question')}
                    </ButtonStyled>
                </FlexLabel>

                <Modal
                    isOpen={isOrderModalOpen}
                    closeHandler={() => setOrderModalIsOpen(false)}
                >
                    <div>
                        <TitleStyled> {t('writeToUs')}</TitleStyled>
                        <Form
                            formName={`Order project ${houseData.node.id}`}
                            buttonText={t('send')}
                            onFormSubmit={handleSubmitStatus}
                            onFormSendStart={handleFormSendStart}
                            formSendStatus={formSendStatus}
                            closeHandler={setOrderModalIsOpen}
                            conversionType="CallBackButtonForm"
                            eventCategory="Callback"
                        >
                            {({ register, errors }: IChildrenProps) => (
                                <InputBlock>
                                    <PhoneInput
                                        ref={register({
                                            minLength: 18,
                                            required: true,
                                        })}
                                        onBlur={() =>
                                            setFormSendStatus('NOT_SEND')
                                        }
                                        err={errors.phone}
                                    />
                                    <MessageInput
                                        ref={register}
                                        err={errors.message}
                                        onBlur={() =>
                                            setFormSendStatus('NOT_SEND')
                                        }
                                    />
                                    <EmailInput
                                        ref={register}
                                        err={errors.email}
                                        onBlur={() =>
                                            setFormSendStatus('NOT_SEND')
                                        }
                                    />
                                    <InputHidden
                                        ref={register}
                                        name="designer"
                                        defaultValue={houseData.node.designer}
                                    />
                                    <InputHidden
                                        ref={register}
                                        name="progect id"
                                        defaultValue={houseData.node.id}
                                    />
                                </InputBlock>
                            )}
                        </Form>
                    </div>
                </Modal>

                <Modal
                    isOpen={isAskModalOpen}
                    closeHandler={() => setAskModalIsOpen(false)}
                >
                    <div>
                        <TitleStyled> {t('designQuestion')}</TitleStyled>
                        <Form
                            formName={`Ask designer ${houseData.node.id}`}
                            buttonText={t('send')}
                            onFormSubmit={handleSubmitStatus}
                            onFormSendStart={handleFormSendStart}
                            formSendStatus={formSendStatus}
                            closeHandler={setAskModalIsOpen}
                            conversionType="HousesForm"
                            eventCategory="Callback"
                        >
                            {({ register, errors }: IChildrenProps) => (
                                <InputBlock>
                                    <PhoneInput
                                        ref={register({
                                            minLength: 18,
                                            required: true,
                                        })}
                                        onBlur={() =>
                                            setFormSendStatus('NOT_SEND')
                                        }
                                        err={errors.phone}
                                    />

                                    <EmailInput
                                        ref={register}
                                        err={errors.email}
                                        onBlur={() =>
                                            setFormSendStatus('NOT_SEND')
                                        }
                                    />
                                    <MessageInput
                                        ref={register}
                                        err={errors.message}
                                        rows={2}
                                        onBlur={() =>
                                            setFormSendStatus('NOT_SEND')
                                        }
                                    />
                                    <InputHidden
                                        ref={register}
                                        name="designer"
                                        defaultValue={houseData.node.designer}
                                    />
                                    <InputHidden
                                        ref={register}
                                        name="progect id"
                                        defaultValue={houseData.node.id}
                                    />
                                </InputBlock>
                            )}
                        </Form>
                    </div>
                </Modal>
                <ModalNew
                    isOpen={isModalOpen}
                    image
                    closeHandler={() => setModalIsOpen(false)}
                >
                    <ImageB
                        fluid={
                            getImageByImageName(
                                data.allImageSharp,
                                houseData.node.previewImage[initialSlideIndex]
                            ).fluid
                        }
                        imgStyle={{ objectFit: 'contain' }}
                        loading="eager"
                        alt={alt[initialSlideIndex]}
                        title={alt[initialSlideIndex]}
                    />
                </ModalNew>
            </Wrapper>
        </Layout>
    )
}

export default HouseLayout

export const query = graphql`
    query($id: String!) {
        allImageSharp {
            edges {
                node {
                    fluid(maxWidth: 4000, quality: 90) {
                        originalName
                        ...GatsbyImageSharpFluid
                    }
                    parent {
                        ... on File {
                            name
                        }
                    }
                }
            }
        }

        allHousesYaml(
            sort: { fields: id, order: ASC }
            filter: { parent: { id: { eq: $id } } }
        ) {
            edges {
                node {
                    id
                    en {
                        description
                        title
                        alt
                        h1
                    }
                    previewImage
                    ru {
                        description
                        title
                        alt
                        h1
                    }
                    uk {
                        description
                        title
                        alt
                        h1
                    }
                    designer

                    filters {
                        houseArea
                        houseWidth
                        houseHeight
                        ceilingHeight

                        project
                        type
                        floor

                        kitchen
                        living
                        dining
                        wardrobe
                        kitchenAndLiving
                        livingAndDining
                        kitchenAndDining
                        kitchenAndLivingAndDining
                        mainBathroom
                        guestBathroom
                        bedroom
                        bedroomAndBathroom
                        childrens
                        childrensAndBathroom
                        childrens2
                        childrens2AndBathroom
                        guest
                        office
                        laundry
                        veranda
                        garageType
                        carsCount
                        basement
                        mansard
                        hallway
                        hall
                        hallwayAndWardrobe
                        boilerOrTechnical
                    }
                }
            }
        }
    }
`
